.main-color{
    color: aliceblue;
}
.headder{
  width: 100%;
  height: 70px;

}
.headerbody {
    
    border-radius: 50px;
      width: 80%;
    list-style-type: none;
    overflow: hidden;
    background-color: #333;
    margin: auto;
  }
  ul {
   list-style-type: none;
      margin: 0;
     
      padding: 0;
      overflow: hidden;
  
    }
    
    li {
      float: left;
    }
    
    li a {
    
      display: block;
      color: rgb(208, 196, 195);
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
    }
    
    li a:hover:not(.active) {
      background-color: #111;
    }
    
    .active {
      background-color: #04AA6D;
    }
    .home{
     margin-left:10px;
    }
    .icon{
    font-size:33px;
    margin-left:10px;
    margin-top:3px;}
    .right{
        float:right;
        margin-right: 10px;
    }
    .right a:hover:not(.active) {
        background-color: #b41818;
        
      }
    .headertitle{
        float:right; 
        color: rgb(174, 52, 52);
        font-size:27px;
        margin-left:10px;
        margin-top:3px;
        margin-right:3px;
       
    }
    .headerprofileicon{
        float:right; 
   margin-left:3px;
        margin-top:3px;
        margin-right:-7px;
       
    }
    div.headerprofileicon img{
      width:45px;
      height: 45px;
       border-radius: 45px;
    }
    




    .headerleftmenu-dropdown {
      display: none;
      background-color: #333;
      color: #cc3030;
      margin-left: 5px;
      float: left;
    width: 80%;
    height: 58PX;
    border-radius:0px 55px 55px 0px;
    padding: 1px 1px;
    z-index: 1;



    }
    
    .headerleftmenu-dropdown-content {
      background-color: #333;
      color: #cc3030;
      display: none;
      position: absolute;
      
      width: 180px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
     
      z-index: 1;
    }
   
    .headerleftmenu-dropdown:hover .headerleftmenu-dropdown-content {
      display: block;
    }
    .headerleftmenu-dropdown li a {
      display: block;
      color: #cc3030;
      padding: 8px 4px;
      text-decoration: none;
      border-radius:55px;
      width: 180px;
    }
    .headerleftmenu-dropdown li{
      font-size: 22px;
    }
    .headerleftmenu-dropdown ul {
      padding: 0;

    }
    .headerrighttitle{
      font-size: 22px;
      width: 100%;
      

    }

@media screen and (max-width: 940px) {
  .headerbody {
    display: none;
}
.headerleftmenu-dropdown{
  display: block;
}
}
@media screen and (max-width: 540px) {

  li a {
      display: block;
      color: rgb(208, 196, 195);
      text-align: center;
      padding: 4px 4px;
      text-decoration: none;
  }
      .movie-item-content::before {
        
          height: 35px;
        }
      .movie-item{
          font-size: 10px;
          height: 200px;
          width: 100px;
         
      }
      .container{
          font-size: 10px;
      }
      .movie-item-title {
          color: var(--text-color);
          font-size: 0.99rem;
          position: relative;
          font-weight: 700;
      }
      li a {
          display: block;
          color: rgb(208, 196, 195);
          text-align: center;
          padding: 2px 7px;
          
          text-decoration: none;
      }
    }