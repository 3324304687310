
.movie-item-title {
    color: var(--text-color);
    font-size: 1.5rem;
    position: relative;
    font-weight: 700;
}








.searchbox{
    width: 95%;
    height: 50px;
    text-align: right;
}

.pagebuttonrow{
    font-size: 1.3rem;
    text-align: center;

}
.pagebutton{
    padding: 7px;
    border-radius: 10px;
}
.pagebuttontext{
    padding: 5px;
}



 
@media screen and (max-width: 940px) {
    .movie-item-title{
        font-size: 0.8rem;
    }
    .pagebuttonrow{
        font-size: 1.3rem;
        text-align: center;
    
    }
    .movie-info {
        display: flex;
        align-items: center;
        font-size: 0.5rem;
        font-weight: 600;
    }
    .movie-info {
        display: flex;
        align-items: center;
        font-size: 0.5rem;
        font-weight: 600;
    }
  }


 
