@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

body {
    padding: 0;
    margin: 0
}

#server-list {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9999999999
}

#server-list .menu-btn {
    padding: 30px;
    margin-right: 10px;
    /* background-image: url(); */
    background-repeat: no-repeat;
    background-position: right;
    cursor: pointer
}

#server-list #servers {
  list-style: none;
  padding: 0;
  display: none;
  margin-top: -43px;
  margin-right: 60px;
}

#server-list #servers li a {
  color: #325170;
  font-variant: small-caps;
  font-size: 15px;
  text-decoration: none;
  padding: 7px 10px 5px;
  display: block;
  font-weight: 600;
  background: #fff;
  margin: 0 0 5px;
  border-radius: 4px;
  border-left: solid 4px #00d2d3;
}

#server-list #servers li a:before {
   content:url('https://mxplayer.codeside.biz.id/uploads/icon-server-g-drive.png'); 
   display: inline-block;
   padding-right: 6px;
   vertical-align: middle;
}

#server-list #servers li:last-child a {
    border: 0;
    border-left: solid 4px #AE3B6A;
}

#server-list #servers li a:hover {
    color: #556ee6
}

#server-list #servers li a.active {
    color: #325170;
}
#server-list #servers li a.active:hover {
    color: #556ee6;
}

#jw_player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important
}

.jw-rightclick { display: none !important; }

.plyr--video,
.videojs-dimensions {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0
}

.videojs-dimensions {
    width: auto;
    height: auto
}

#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background: #0c111f
}

.overflow, .circle-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.overflow {
    z-index: 9998;
    width: 100vw;
    height: 100vh;
}

.circle-line {
    z-index: 9999;
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.circle-red,.circle-green,.circle-yellow,.circle-blue{
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin: 5px;
}

.circle-red {
    background-color: transparent;
    animation: movingUp 0.5s infinite alternate;
    border:solid 2px #AE3B6A;
}

.circle-blue {
    background-color: transparent;
    animation: movingUp 0.5s 0.2s infinite alternate;
    border:solid 2px #AE3B6A;
}

.circle-green {
    background-color: transparent;
    animation: movingUp 0.5s .5s infinite alternate;
    border:solid 2px #AE3B6A;
}

.circle-yellow {
    background-color: transparent;
    animation: movingUp 0.5s .7s infinite alternate;
    border:solid 2px #AE3B6A;
}

/* CSS ANIMATION */

@keyframes movingUp {
    from {
    
        top: 0px;
        
    }

    to {
        
        top: -30px;
    }
}