.page{
    top: 10px;
    padding-left: 10%;
    padding-right: 10%;
    height: auto;
    text-decoration: none;



}


.containerh{
    border-radius: 20px;
    background-image: linear-gradient(135deg,#eadeff,#bed3fa 25%,#f1fcff 70%,#a6f3ea);margin: 10px;
height: auto;


}
.datapic{


    text-align: right;
    margin: auto;
    padding: 30px;


}
.condata h1{
    size: 20px;

}
.containerh img #peotvgo{
    width :auto;
    height:180px;
}
.containerh img {
    float: right;
    width :auto;
    height:200px;
    /* width:43%;height:auto;margin-left:15px; */
    margin: 10px;
    border-radius:70px 20px 20px 70px;
  }
  .containerh:hover{
    border: 1px solid red;
  }
.condata{

    margin: 20px;
    padding: 20px;
    width: 45%;
    text-align: left;
    color: black;
}
@media screen and (max-width: 540px) {
    .condata h1{
        font-size: 12px;
    
    }
    .containerh{
        font-size:8px;

    }
    .containerh img {
        float: right;
        width :auto;
        height:70px;
        /* width:43%;height:auto;margin-left:15px; */
        margin: 10px;
        border-radius:20px 5px 5px 20px;
      }
}
