/* PLAYBACK */
.jw-state-idle .jw-svg-icon-play path {
	display: none;
}

.jw-state-idle .jw-svg-icon-play {
	background-image: url("./svg/play-idle.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.jw-state-idle .jw-display-icon-container:hover .jw-svg-icon-play {
	background-image: url("./svg/play-idle-hover.svg");
}

.jw-state-paused .jw-svg-icon-play path {
	display: none;
}

.jw-state-paused .jw-svg-icon-play {
	background-image: url("./svg/play.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.jw-state-paused .jw-icon-playback:hover .jw-svg-icon-play {
	background-image: url("./svg/play-hover.svg");
}


/* REPLAY */
.jw-svg-icon-replay path {
	display: none;
}

.jw-svg-icon-replay {
	background-image: url("./svg/replay.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.jw-icon-replay:hover .jw-svg-icon-replay {
	background-image: url("./svg/replay-hover.svg");
}

/* REWIND */
.jw-svg-icon-rewind path {
	display: none;
}

.jw-svg-icon-rewind {
	background-image: url("./svg/rewind.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.jw-icon-rewind:hover .jw-svg-icon-rewind {
	background-image: url("./svg/rewind-hover.svg");
}

/* CLOSED CAPTIONS */
.jw-svg-icon-cc-off path {
	display: none;
}

.jw-svg-icon-cc-off {
	background-image: url("./svg/captions.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.jw-icon-cc-off:hover .jw-svg-icon-cc-off {
	background-image: url("./svg/captions-hover.svg");
}

/* CLOSED CAPTIONS */
.jw-svg-icon-cc-on path {
	display: none;
}

.jw-svg-icon-cc-on {
	background-image: url("./svg/captions-on.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.jw-icon-cc-on:hover .jw-svg-icon-cc-on {
	background-image: url("./sub.png");
}

/* QUALITY */
.jw-svg-icon-quality-100 path {
	display: none;
}

.jw-svg-icon-quality-100 {
	background-image: url("./svg/quality.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.jw-settings-quality:hover .jw-svg-icon-quality-100 {
	background-image: url("./svg/quality-hover.svg");
}
